import { Card, Col, Row } from "antd";
import { Link } from "react-router-dom";

export const Hero = () => {
  return (
    <Row
      style={{
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        background: "white",
        paddingTop: "15px",
      }}
    >
      <Col xs={24} sm={24} md={24}>
        <Link to={"protected"}>
          <Card
            title=""
            bordered={false}
            // style={{
            //   width: 300,
            //   textAlign: "center",
            // }}
            hoverable
          >
            <div style={{ padding: "75% 0 0 0", position: "relative" }}>
              <iframe
                src="https://player.vimeo.com/video/798744340?h=68ff60b81b&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                frameborder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                allowfullscreen
                style={{
                  position: "absolute",
                  top: "0",
                  left: "0",
                  width: "100%",
                  height: "100%",
                }}
                title="valetines-2023 - SD 480p.mov"
              ></iframe>
            </div>
            <script src="https://player.vimeo.com/api/player.js"></script>
            <img
              width={"50px"}
              src={
                "https://upload.wikimedia.org/wikipedia/en/thumb/f/f5/Handala.gif/220px-Handala.gif"
              }
              alt="handala"
            />
          </Card>
        </Link>
      </Col>
    </Row>
  );
};
