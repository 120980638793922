import { Col, Divider, Row, Space } from "antd";

export const Footer = () => {
  return (
    <div>
      <Row gutter={[18, 18]} style={{ margin: "20px", width: "100%" }}>
        <Divider></Divider>
        <Col xs={24} sm={24} md={9}>
          <p style={{ fontWeight: "900" }}>chantal</p>
          <p style={{ fontSize: "14px" }}>your personal site</p>
          <Space>
            <p>Happy Valentines Day Baby!!!! Welcome your very own site!</p>
          </Space>
        </Col>
        <Col xs={12} sm={12} md={5}>
          <p style={{ fontWeight: "700" }}>Quick Links</p>
          <ul style={{ listStyleType: "none", padding: "0px" }}>
            <li>
              <a
                style={{
                  fontSize: "14px",
                  textDecoration: "none",
                  color: "salmon",
                }}
                href={"https://eastwest.lk"}
                target="_blank"
                rel="noopener noreferrer"
              >
                gmail
              </a>
            </li>
          </ul>
        </Col>
        <Col xs={12} sm={12} md={5}>
          <p style={{ fontWeight: "700" }}>Social</p>
          <ul style={{ listStyleType: "none", padding: "0px" }}>
            <li>
              <a
                style={{
                  fontSize: "14px",
                  textDecoration: "none",
                  color: "salmon",
                }}
                href={"https://www.linkedin.com/in/chantal-sirisena/"}
                target="_blank"
                rel="noopener noreferrer"
              >
                Linkedin
              </a>
            </li>
          </ul>
        </Col>
        <Divider></Divider>
        <Col span={24}>
          <div style={{ textAlign: "center" }}>
            <p style={{ fontSize: "12px" }}>
              built by Viji for Chantal &copy; 2023
            </p>
          </div>
        </Col>
      </Row>
    </div>
  );
};
