import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Button, Card } from "antd";

export const LoginButton = () => {
  const { loginWithRedirect } = useAuth0();

  return <Button onClick={() => loginWithRedirect()}>Log In</Button>;
};

export const LoginHero = () => {
  const { loginWithRedirect } = useAuth0();

  return (
    <Card
      onClick={() => loginWithRedirect()}
      title=""
      bordered={false}
      // style={{
      //   width: 300,
      //   textAlign: "center",
      // }}
      hoverable
    >
      <img
        src={
          "https://upload.wikimedia.org/wikipedia/en/thumb/f/f5/Handala.gif/220px-Handala.gif"
        }
        alt="handala"
      />
    </Card>
  );
};

export const LogoutButton = ({ type }) => {
  const { logout } = useAuth0();

  return (
    <Button
      type={type}
      onClick={() => logout({ returnTo: window.location.origin })}
    >
      {"Logout"}
    </Button>
  );
};
