import React from "react";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
// import { Auth0ProviderWithConfig } from "./utils/auth0/auth0Provider";

import { createRoot } from "react-dom/client";
import { Auth0Provider } from "@auth0/auth0-react";
const container = document.getElementById("root");
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  <React.StrictMode>
    {/* <Auth0ProviderWithConfig> */}
    <Auth0Provider
      domain="app-chantal.us.auth0.com"
      clientId="cQHSNVHBRYB51krkmpbgGLtW4eIBteKX"
      authorizationParams={{
        redirect_uri: window.location.origin,
      }}
    >
      <App />
    </Auth0Provider>
    ,{/* </Auth0ProviderWithConfig> */}
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
