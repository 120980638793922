import { Hero } from "../components/landingPage/hero";
import { Footer } from "../components/navigation/footer";
import { DynamicMetaTags } from "../utils/dynamicMetaTags";

export const LandingPage = () => {
  return (
    <div style={{ justifyContent: "center", display: "flex" }}>
      <DynamicMetaTags ogTitle={`~chantal~`} ogImage={""} />
      <div>
        <Hero />
        <Footer />
      </div>
    </div>
  );
};
