import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import { NotFound } from "./utils/notFound";
import { NavBar } from "./components/navigation/navBar";
import { LandingPage } from "./pages/LandingPage";
import { Protected } from "./pages/Protected";
import { ProtectedRoute } from "./utils/auth0/protectedRoute";

function App() {
  return (
    <Router>
      <NavBar />
      <Routes>
        <Route exact path="/" element={<LandingPage />} />
        <Route
          exact
          path="/protected"
          element={<ProtectedRoute component={Protected} />}
        />
        <Route exact path="/*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default App;
